import { useEffect, useState } from "react";
import "../App.css";
import Profile from "./Profile";
import GraphService from "../services/GraphService";

function Sso() {
 // Extracting the 'code' parameter from the URL query string (used for authorization)
 const urlParams = new URLSearchParams(window.location.search);
 const code = urlParams.get("code");
 const state = urlParams.get("state");


 // State to store the retrieved user data
 const [data, setData] = useState(null);
 // State to indicate if data is being fetched
 const [loading, setLoading] = useState(false);


 // Runs whenever the 'code' variable changes (likely on authorization flow)
 useEffect(() => {
   const token = localStorage.getItem("token");
   if (token) {
     setLoading(true); // Set loading to true while fetching data
     fetch("https://api.github.com/user", {
       headers: { Authorization: token },
     })
       .then((res) => res.json()) // Parse the response as JSON
       .then((data) => {
         setData(data); // Update state with fetched user data
         setLoading(false); // Set loading to false when done fetching
       });
   } else if (code) {
     console.log(code);
     console.log(state);
     // If no token but 'code' is available (GitHub OAuth flow)
     setLoading(true); // Set loading to true while fetching data

     GraphService.getSsoToken(code, state)
      .then((res) => {
         setData(res.data.userData); // Update state with user data from response
         localStorage.setItem(
           "token",
           `${res.data.tokenType} ${res.data.token}`
         ); // Store access token in local storage
         setLoading(false); // Set loading to false when done fetching
      })
      .catch(e => {
        console.log(e);
      });

   }
 }, [code,state]);


 // Function to redirect the user to the GitHub OAuth authorization page
 const redirectToGitHub = async () => {
    const respUrl = await GraphService.getSsoUrl("");
    const authUrl = respUrl.data.url;
    console.log(respUrl.data);

    window.location.href = authUrl;
 }


 // Conditionally render content based on loading state and data availability
 if (loading) {
   return <h4>Loading...</h4>;
 }


 if (data) {
   return <Profile user={data} />;
 }


 return (
   <>
     <div className="login-container">
       <h1>Login via Github using Single-Sign-On(SSO) OAuth2 </h1>
       <button className="github-button" onClick={redirectToGitHub}>
         <img
           src="https://github.githubassets.com/images/modules/logos_page/GitHub-Mark.png"
           alt="GitHub Logo"
         />
         Login with GitHub
       </button>
     </div>
   </>
 );
}


export default Sso;
