import { useState, useEffect } from "react";
import { Typography } from "../components/shared/Typography";
import PatternService from "../services/PatternService";
import PatternRun from "./PatternRun";

const Pattern = ({p}) => {
  const [docs, setDocs] = useState("Documentation for pattern: " + p.name);
  let url = "http://pattern.gak.solutions/show?q=" + p.name;

  useEffect(() => {
    retrieveDoc();
  }, [p.name]);

  const retrieveDoc = () => {
    PatternService.getDoc(p.name)
      .then((response) => {
        // console.log(response.data);
        setDocs(response.data);
      })  
      .catch((e) => {
        console.log(e);
      }); 
  };

  return (
    <div style={{ padding: "16px 24px", color: "#44596e" }}>
      <div style={{ marginBottom: "48px" }}>
        <Typography variant="h4" fontWeight={600}>
          <a href={url}>Pattern: {p.title}</a>
        </Typography>
        <pre style={{ fontFamily: "monospace" }}>{docs}</pre>
        <PatternRun p={p}/>
      </div>
    </div>
  );
};

export default Pattern;
