import React from 'react';
import { Container, Grid } from 'semantic-ui-react';
import { Document, Page } from 'react-pdf';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';


class AppHome extends React.Component {

  state = {
    file: "GAK.pdf",
    numPages: 0,
    pageNumber: 1
  }

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  }

  nextPage = () => {

    const currentPageNumber = this.state.pageNumber;
    let nextPageNumber;

    if (currentPageNumber + 1 > this.state.numPages) {
      nextPageNumber = 1;
    } else {
      nextPageNumber = currentPageNumber + 1;
    }

    this.setState({
      pageNumber: nextPageNumber
    });
  }

  render() {
    const { pageNumber, numPages } = this.state;

    return (
      <Container>
        <Grid centered columns={2}>
          <Grid.Column textAlign="center" onClick={this.nextPage}>

            <Document file='GAK.pdf' onLoadSuccess={this.onDocumentLoadSuccess}>
              <Page pageNumber={pageNumber} />
            </Document>

            {this.state.file ? <p>Page {pageNumber} of {numPages}</p> : null}
          </Grid.Column>
        </Grid>
      </Container>
    );
  }
}

export default AppHome;
