import {
  Sidebar,
  Menu,
  MenuItem,
  SubMenu,
} from "react-pro-sidebar";
import React, { useState } from "react";

import Home from "../../features/Home";
import Dashboard from "../../features/Dashboard";
import Pattern from "../../features/Pattern";
import Invoices from "../../features/Invoices";
import RegistrationForm from "../../features/MiRegForm";
import Subnet from "../../features/Subnet";
import Emi from "../../features/Emi";
import Epoch from "../../features/Epoch";
import GraphData from "../../features/GraphData";
import GraphDataShortest from "../../features/GraphDataShortest";
import Sso from "../../features/Sso";

import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded";
import ReceiptRoundedIcon from "@mui/icons-material/ReceiptRounded";
import BarChartRoundedIcon from "@mui/icons-material/BarChartRounded";
import TimelineRoundedIcon from "@mui/icons-material/TimelineRounded";
import BubbleChartRoundedIcon from "@mui/icons-material/BubbleChartRounded";
import AccountBalanceRoundedIcon from "@mui/icons-material/AccountBalanceRounded";

import CloudRoundedIcon from '@mui/icons-material/CloudRounded';
import NetworkPingRoundedIcon from '@mui/icons-material/NetworkPingRounded';
import AccessTimeFilledRoundedIcon from '@mui/icons-material/AccessTimeFilledRounded';

import DvrRoundedIcon from '@mui/icons-material/DvrRounded';
import EditNoteRoundedIcon from '@mui/icons-material/EditNoteRounded';

/*
import Transactions from "../../features/Transactions";
import Tooltip from "@mui/material/Tooltip";
import MiCard from "../../features/MiCard";
import RegistrationForm1 from "../../features/MiRegForm1";
import DataGridTable from "../../features/DataGridTable";
import DarkModeToggle from "./DarkModeToggle";
import { width } from "@mui/system";
*/

/*
import SavingsRoundedIcon from "@mui/icons-material/SavingsRounded";
import MonetizationOnRoundedIcon from "@mui/icons-material/MonetizationOnRounded";
import SettingsApplicationsRoundedIcon from "@mui/icons-material/SettingsApplicationsRounded";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import ShieldRoundedIcon from "@mui/icons-material/ShieldRounded";
import NotificationsRoundedIcon from "@mui/icons-material/NotificationsRounded";
import WalletRoundedIcon from "@mui/icons-material/WalletRounded";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
*/

import { Routes, Route, Link } from "react-router-dom";

const Sidenav = () => {
  const [menuCollapse, setMenuCollapse] = useState(false);
  const menuIconClick = () => {
    menuCollapse ? setMenuCollapse(false) : setMenuCollapse(true);
  };

  
  const triangles = [
    { key: 1, name: "custom_string_right_angle_triangle", rt: "customstringrightangletriangle", title: "Custom String Right Angle", lbl: "Text: ", def: "PYTHON"  },
    { key: 2, name: "decreasing_number_triangle", rt: "decreasingnumbertriangle", title: "Decreasing Number", lbl: "Rows: ", def: 5  },
    { key: 3, name: "isosceles_triangle_pointing_down", rt: "isoscelestrianglepointingdown", title: "Isosceles Down", lbl: "Rows: ", def: 5  },
    { key: 4, name: "isosceles_triangle_pointing_up", rt: "isoscelestrianglepointingup", title: "Isosceles Up", lbl: "Rows: ", def: 5  },
    { key: 5, name: "narrow_isosceles_triangle_pointing_down", rt: "narrowisoscelestrianglepointingdown", title: "Narrow Isosceles Down", lbl: "Rows: ", def: 5  },
    { key: 6, name: "narrow_isosceles_triangle_pointing_up", rt: "narrowisoscelestrianglepointingup", title: "Narrow Isosceles Up", lbl: "Rows: ", def: 5  },
    { key: 7, name: "narrow_string_isosceles_triangle_pointing_down", rt: "narrowstringisoscelestrianglepointingdown", title: "String Narrow Isosceles Down", lbl: "Text: ", def: "ABCDE"  },
    { key: 8, name: "narrow_string_isosceles_triangle_pointing_up", rt: "narrowstringisoscelestrianglepointingup", title: "String Narrow Isosceles Up", lbl: "Text: ", def: "ABCDE"  },
    { key: 9, name: "number_increasing_along_column_isosceles_triangle_pointing_up", rt: "numberincreasingalongcolumnisoscelestrianglepointingup", title: " Number Isosceles Up", lbl: "Rows: ", def: 5  },
    { key: 10, name: "number_increasing_along_column_right_angle_triangle", rt: "numberincreasingalongcolumnrightangletriangle", title: "Number Rt Angle  Up", lbl: "Rows: ", def: 5  },
    { key: 11, name: "number_increasing_along_column_right_angle_triangle_inverted_across_h_and_v_plane", rt: "numberincreasingalongcolumnrightangletriangleinvertedacrosshandvplane", title: "Number Rt Angle Inverted", lbl: "Rows: ", def: 5  },
    { key: 12, name: "number_increasing_along_column_right_angle_triangle_inverted_across_hplane", rt: "numberincreasingalongcolumnrightangletriangleinvertedacrosshplane", title: "Number Rt Angle Inverted Horizontal", lbl: "Rows: ", def: 5  },
    { key: 13, name: "number_increasing_along_column_right_angle_triangle_inverted_across_vplane", rt: "numberincreasingalongcolumnrightangletriangleinvertedacrossvplane", title: "Number Rt Angle Inverted Vertical", lbl: "Rows: ", def: 5  },
    { key: 14, name: "number_increasing_along_row_right_angle_triangle", rt: "numberincreasingalongrowrightangletriangle", title: "Number Increasing Rt Angle", lbl: "Rows: ", def: 5  },
    { key: 15, name: "numbers_indicating_column_num_inverted", rt: "numbersindicatingcolumnnuminverted", title: "Number Indicating Inverted", lbl: "Rows: ", def: 5  },
    { key: 16, name: "right_angle_triangle_inverted_across_h_and_v_plane", rt: "rightangletriangleinvertedacrosshandvplane", title: "Rt Angle Inverted", lbl: "Rows: ", def: 5  },
    { key: 17, name: "right_angle_triangle_inverted_across_hplane", rt: "rightangletriangleinvertedacrosshplane", title: "Rt Angle Inverted Horizontal", lbl: "Rows: ", def: 5  },
    { key: 18, name: "right_angle_triangle_inverted_across_vplane", rt: "rightangletriangleinvertedacrossvplane", title: "Rt Angle Inverted Vertical", lbl: "Rows: ", def: 5  },
    { key: 19, name: "right_angle_triangle_pattern", rt: "rightangletrianglepattern", title: "Rt Angle", lbl: "Rows: ", def: 5  },
    { key: 20, name: "same_number_as_row_right_angle_triangle", rt: "samenumberasrowrightangletriangle", title: "Rt Angle Number", lbl: "Rows: ", def: 5  },
    { key: 21, name: "string_right_angle_triangle", rt: "stringrightangletriangle", title: "String Rt Angle Number", lbl: "Text: ", def: "ABCDEF"  },
    { key: 22, name: "string_right_angle_triangle_inverted_across_h_and_v_plane", rt: "stringrightangletriangleinvertedacrosshandvplane", title: "String Rt Angle ", lbl: "Text: ", def: "ABCDEF"  },
    { key: 23, name: "string_right_angle_triangle_inverted_across_hplane", rt: "stringrightangletriangleinvertedacrosshplane", title: "String Rt Angle Horizontal", lbl: "Text: ", def: "ABCDEF"  },
    { key: 24, name: "string_right_angle_triangle_inverted_across_vplane", rt: "stringrightangletriangleinvertedacrossvplane", title: "String Rt Angle Vertical", lbl: "Text: ", def: "ABCDEF"  },
    { key: 25, name: "string_triangle_pointing_right", rt: "stringtrianglepointingright", title: "String Rt", lbl: "Text: ", def: "ABCDEF"  },
    { key: 26, name: "triangle_pointing_left", rt: "trianglepointingleft", title: "String Left", lbl: "Rows: ", def: 5  },
    { key: 27, name: "triangle_pointing_left_double_center_row", rt: "trianglepointingleftdoublecenterrow", title: "String Left Double Centre", lbl: "Rows: ", def: 5  },
    { key: 28, name: "triangle_pointing_right", rt: "trianglepointingright", title: "String Rt Centre", lbl: "Rows: ", def: 5  },
    { key: 29, name: "triangle_pointing_right_double_center_row", rt: "trianglepointingrightdoublecenterrow", title: "String Rt Double Centre", lbl: "Rows: ", def: 5  },
    { key: 30, name: "wide_string_isosceles_triangle_pointing_up", rt: "widestringisoscelestrianglepointingup", title: "Wide String Up", lbl: "Text: ", def: "ABCDE"  },
  ];

  const pyramids = [
    { key: 31, name: "hollow_pyramid_pointing_down", rt: "hollowpyramidpointingdown", title: "Hollow Pyramid Down", lbl: "Rows: ", def: 5  },
    { key: 32, name: "hollow_pyramid_pointing_up", rt: "hollowpyramidpointingup", title: "Hollow Pyramid Up", lbl: "Rows: ", def: 5  },
    { key: 33, name: "pyramid_pointing_down", rt: "pyramidpointingdown", title: "Pyramid Pointing Down", lbl: "Rows: ", def: 5  },
    { key: 34, name: "pyramid_pointing_up", rt: "pyramidpointingup", title: "Pyramid Pointing Up", lbl: "Rows: ", def: 5  },
    { key: 35, name: "decreasing_number_pyramid_pointing_down", rt: "decreasingnumberpyramidpointingdown", title: "Decreasing Pyramid Down", lbl: "Rows: ", def: 5  },
    { key: 36, name: "decreasing_number_pyramid_pointing_up", rt: "decreasingnumberpyramidpointingup", title: "Decreasing Pyramid Up", lbl: "Rows: ", def: 5  },
    { key: 37, name: "increasing_number_pyramid_pointing_down", rt: "increasingnumberpyramidpointingdown", title: "Increasing Pyramid Down", lbl: "Rows: ", def: 5  },
    { key: 38, name: "increasing_number_pyramid_pointing_up", rt: "increasingnumberpyramidpointingup", title: "Increasing Pyramid Up", lbl: "Rows: ", def: 5  },
    { key: 39, name: "string_pyramid_pointing_down", rt: "stringpyramidpointingdown", title: "String Pyramid Down", lbl: "Text: ", def: "ABCDE"  },
    { key: 40, name: "string_pyramid_pointing_up", rt: "stringpyramidpointingup", title: "String Pyramid Up", lbl: "Text: ", def: "ABCDE"  },
  ];

  const miscellaneous = [
    { key: 41, name: "number_7", rt: "number7", title: "Number-7", lbl: "Rows: ", def: 5  },
    { key: 42, name: "inverted_number_7", rt: "invertednumber7", title: "Inverted-7", lbl: "Rows: ", def: 5  },
    { key: 43, name: "inverted_z", rt: "invertedz", title: "Inverted-Z", lbl: "Rows: ", def: 5  },
    { key: 44, name: "pyramid_diamond", rt: "pyramiddiamond", title: "Pyramid Diamond", lbl: "Rows: ", def: 5  },
    { key: 45, name: "inverted_sigma", rt: "invertedsigma", title: "Inverted-SIGMA", lbl: "Rows: ", def: 5  },
    { key: 46, name: "pyramid_hour_glass", rt: "pyramid_hour_glass", title: "Pyramid Hour Glass", lbl: "Rows: ", def: 5  },
    { key: 47, name: "sigma", rt: "sigma", title: "Sigma", lbl: "Rows: ", def: 5  },
  ];

  const hourglass = [
    { key: 51, name: "horizontal_hour_glass", rt: "horizontalhourglass", title: "Horizontal Hour Glass", lbl: "Rows: ", def: 5  },
    { key: 52, name: "string_hour_glass", rt: "stringhourglass", title: "String Hour Glass", lbl: "Text: ", def: "ABCDE"  },
    { key: 53, name: "horizontal_hour_glass_common_midpoint", rt: "horizontalhourglasscommonmidpoint", title: "Horizontal Hour Glass Common Midpoint", lbl: "Rows: ", def: 5  },
    { key: 54, name: "hollow_hour_glass", rt: "hollowhourglass", title: "Hollow Hour Glass", lbl: "Rows: ", def: 5  },
    { key: 55, name: "hour_glass", rt: "hourglass", title: "Hour Glass", lbl: "Rows: ", def:5 },
  ];

  const diamonds = [
    { key: 61, name: "hollow_diamond", rt: "hollowdiamond", title: "Hollow Diamond", lbl: "Rows: ", def: 5  },
    { key: 62, name: "narrow_string_diamond", rt: "narrowstringdiamond", title: "Narrow String Diamond", lbl: "Text: ", def: "ABCDE"  },
    { key: 63, name: "wide_string_diamond", rt: "widestringdiamond", title: "Wide String Diamond", lbl: "Text: ", def: "ABCDE"  },
    { key: 64, name: "narrow_diamond", rt: "narrowdiamond", title: "Narrow Diamond", lbl: "Rows: ", def: 5  },
    { key: 65, name: "wide_diamond", rt: "widediamond", title: "Wide Diamond", lbl: "Rows: ", def: 5 },
  ];

  const alphabets = [
    { key: 71, name: "a_pattern", rt: "apattern", title: "A-Pattern", lbl: "Rows: ", def: 5  },
    { key: 72, name: "e_pattern", rt: "epattern", title: "E-Pattern", lbl: "Rows: ", def: 5  },
    { key: 73, name: "f_pattern", rt: "fpattern", title: "F-Pattern", lbl: "Rows: ", def: 5  },
    { key: 74, name: "h_pattern", rt: "hpattern", title: "H-Pattern", lbl: "Rows: ", def: 5  },
    { key: 75, name: "i_pattern", rt: "ipattern", title: "I-Pattern", lbl: "Rows: ", def: 5  },
    { key: 76, name: "l_pattern", rt: "lpattern", title: "L-Pattern", lbl: "Rows: ", def: 5  },
    { key: 77, name: "t_pattern", rt: "tpattern", title: "T-Pattern", lbl: "Rows: ", def: 5  },
    { key: 78, name: "v_pattern", rt: "vpattern", title: "V-Pattern", lbl: "Rows: ", def: 5  },
    { key: 79, name: "x_pattern", rt: "xpattern", title: "X-Pattern", lbl: "Rows: ", def: 5  },
    { key: 80, name: "z_pattern", rt: "zpattern", title: "Z-Pattern", lbl: "Rows: ", def: 5  },
  ];

/*
  const style3 = {
    width: "90%",
  };
*/

  return (
    <div style={{ display: "flex", height: "100vh" }}>
      <Sidebar className="app" collapsed={menuCollapse}>
        <Menu>
          {/* <Tooltip
            title="Zoom In"
            arrow
            placement="right"
            hidden={menuCollapse ? true : false}
            followCursor
          > */}
          <MenuItem
            className="menu1"
            icon={
              <MenuRoundedIcon
                onClick={() => {
                  menuIconClick();
                }}
              />
            }
            component={<Link to="/" className="link" />}
          >
            <h2> GAK's</h2>
          </MenuItem>
          {/* </Tooltip> */}
          <br />
          <MenuItem
            icon={<GridViewRoundedIcon />}
            component={<Link to="dashboard" className="link" />}
          >
            {" "}
            Dashboard{" "}
          </MenuItem>
          <MenuItem
            icon={<ReceiptRoundedIcon />}
            component={<Link to="link" className="link" />}
          >
            Links
          </MenuItem>
          <SubMenu label="Graph(Neo4j)" icon={<ReceiptRoundedIcon />}>
          <MenuItem
            icon={<ReceiptRoundedIcon />}
            component={<Link to="graph" className="link" />}
          >
            Graph Links
          </MenuItem>
          <MenuItem
            icon={<ReceiptRoundedIcon />}
            component={<Link to="shortestpath" className="link" />}
          >
            Shortest Path
          </MenuItem>
          </SubMenu>
          <SubMenu label="Patterns" icon={<BarChartRoundedIcon />}>
            <SubMenu label="Alphabets" icon={<BarChartRoundedIcon />}>
              {alphabets.map((pattern, i) => ( 
              <MenuItem key={pattern.key} icon={<TimelineRoundedIcon />} 
                component={<Link to={"/" + pattern.rt} className="link" />}
              >
              {pattern.title} 
              </MenuItem>
              ))}
            </SubMenu>

            <SubMenu label="Diamonds" icon={<BarChartRoundedIcon />}>
              {diamonds.map((pattern, i) => ( 
              <MenuItem key={pattern.key} icon={<BubbleChartRoundedIcon />} 
                component={<Link to={"/" + pattern.rt} className="link" />}
              >
              {pattern.title} 
              </MenuItem>
              ))}
            </SubMenu>

            <SubMenu label="HourGlass" icon={<BarChartRoundedIcon />}>
              {hourglass.map((pattern, i) => ( 
              <MenuItem key={pattern.key} icon={<BubbleChartRoundedIcon />} 
                component={<Link to={"/" + pattern.rt} className="link" />}
              >
              {pattern.title} 
              </MenuItem>
              ))}
            </SubMenu>

            <SubMenu label="Miscellaneous" icon={<BarChartRoundedIcon />}>
              {miscellaneous.map((pattern, i) => ( 
              <MenuItem key={pattern.key} icon={<BubbleChartRoundedIcon />} 
                component={<Link to={"/" + pattern.rt} className="link" />}
              >
              {pattern.title} 
              </MenuItem>
              ))}
            </SubMenu>

            <SubMenu label="Pyramids" icon={<BarChartRoundedIcon />}>
              {pyramids.map((pattern, i) => ( 
              <MenuItem key={pattern.key} icon={<BubbleChartRoundedIcon />} 
                component={<Link to={"/" + pattern.rt} className="link" />}
              >
              {pattern.title} 
              </MenuItem>
              ))}
            </SubMenu>

            <SubMenu label="Triangles" icon={<BarChartRoundedIcon />}>
              {triangles.map((pattern, i) => ( 
              <MenuItem key={pattern.key} icon={<BubbleChartRoundedIcon />} 
                component={<Link to={"/" + pattern.rt} className="link" />}
              >
              {pattern.title} 
              </MenuItem>
              ))}
            </SubMenu>

          </SubMenu>
          <SubMenu label="Azure Functions" icon={<CloudRoundedIcon />}>
            <MenuItem icon={<AccountBalanceRoundedIcon />}
              component={<Link to="/emi" className="link" />}
            >
            Ammortisation
            </MenuItem>
            <MenuItem  icon={<NetworkPingRoundedIcon />}
              component={<Link to="/subnet" className="link" />}
            >
            Subnet Calculation
            </MenuItem>
            <MenuItem  icon={<AccessTimeFilledRoundedIcon />}
              component={<Link to="/epoch" className="link" />}
            >
            Epoch Converter
            </MenuItem>
          </SubMenu>

          {/*<SubMenu label="Miscellaneous" icon={<DvrRoundedIcon />}>
            <MenuItem
              icon={<EditNoteRoundedIcon />}
              component={<Link to="/regform" className="link" />}
            >
            Registration Form
            </MenuItem>
          </SubMenu>*/}
          <MenuItem
            icon={<ReceiptRoundedIcon />}
            component={<Link to="sso" className="link" />}
          >
            Github SSO
          </MenuItem>

          {/*
          <MenuItem
            icon={<MonetizationOnRoundedIcon />}
            component={<Link to="transactions" className="link" />}
          >
            Transactions
          </MenuItem>
          <SubMenu label="Settings" icon={<SettingsApplicationsRoundedIcon />}>
            <MenuItem icon={<AccountCircleRoundedIcon />}> Account </MenuItem>
            <MenuItem icon={<ShieldRoundedIcon />}> Privacy </MenuItem>
            <MenuItem icon={<NotificationsRoundedIcon />}>
              Notifications
            </MenuItem>
          </SubMenu>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <MenuItem icon={<LogoutRoundedIcon />}> Logout </MenuItem>
          */}
        </Menu>
      </Sidebar>

      <main>
        <div className="parent">
          <div>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="link" element={<Invoices />} />
              <Route path="sso" element={<Sso />} />
              <Route path="graph" element={<GraphData />} />
              <Route path="shortestpath" element={<GraphDataShortest />} />
              {triangles.map((pattern, i) => ( 
              <Route key={pattern.key} path={pattern.rt} element={<Pattern p={pattern} />} />
              ))}
              {pyramids.map((pattern, i) => ( 
              <Route key={pattern.key} path={pattern.rt} element={<Pattern p={pattern} />} />
              ))}
              {miscellaneous.map((pattern, i) => ( 
              <Route key={pattern.key} path={pattern.rt} element={<Pattern p={pattern} />} />
              ))}
              {hourglass.map((pattern, i) => ( 
              <Route key={pattern.key} path={pattern.rt} element={<Pattern p={pattern} />} />
              ))}
              {diamonds.map((pattern, i) => ( 
              <Route key={pattern.key} path={pattern.rt} element={<Pattern p={pattern} />} />
              ))}
              {alphabets.map((pattern, i) => ( 
              <Route key={pattern.key} path={pattern.rt} element={<Pattern p={pattern} />} />
              ))}
              <Route path="subnet" element={<Subnet />} />
              <Route path="emi" element={<Emi />} />
              <Route path="epoch" element={<Epoch />} />
//              <Route path="regform" element={<RegistrationForm />} />
            </Routes>
          </div>
        </div>
      </main>
    </div>
  );
};
export default Sidenav;
