import { Typography } from "../components/shared/Typography";
import React, { useState, useEffect } from 'react'
import { DataGrid } from '@mui/x-data-grid'
import TextField from '@mui/material/TextField';
import { Button } from "@mui/material";
import MenuItem from '@mui/material/MenuItem';
import GraphService from "../services/GraphService";

const columns = [
  { field: 'link', headerName: '#', width: 50 },
  { field: 'path', headerName: 'Links', width: 600},
]

const GraphData = () => {
  
  const [actors, setActors] = useState([]);

  const [fld, setFld] = useState({
    actor: "Tom Hanks",
    depth: 3
  });

  useEffect(() => {
    retrieveData();
  }, []);

  const retrieveData = () => {
    GraphService.getActors()
      .then((response) => {
        // console.log(response.data);
        setActors(response.data);
      })  
      .catch((e) => {
        console.log(e);
      }); 
  };

  const handleActorChange = (event) => {
    console.log(event.target.value);
    setFld({...fld, [event.target.name]: event.target.value});
    console.log(fld)
  };  

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    console.log(name, value);
    setFld({...fld, [name]: value});
    console.log(fld)
  };  


  const handleGetRowId = (e) => {
        return e.link
  }

  const runShortestPaths = () => {
    GraphService.getUptoLinks(fld.actor, fld.depth)
      .then(response => {
         //console.log(response.data);
         setTableData(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  };

  const [tableData, setTableData] = useState([])

  const handleSubmit = (event) => {
    event.preventDefault();
    runShortestPaths();
  };

  const title = "Actor";
  const depth = "Depth";

  return (
    <div>
        <Typography variant="h6" fontWeight={400}>
          Find Graph Links upto depth 'N' for Nodes(Actors, Movies)
        </Typography>
    <div style={{ width: '100%' }}>
        <TextField fullWidth
          id="actor"
          name="actor"
          select
          label={title}
          defaultValue={fld.actor}
          size="medium"
          margin="normal"
          onChange={handleActorChange}
        >
          {actors.map((option) => (
            <MenuItem key={option[0]} value={option[0]}>
              {option[0]}
            </MenuItem>
          ))}
        </TextField>
        <TextField label={depth}
          fullWidth
          id="depth"
          name="depth"
          value={fld.depth}
          type="number"
          size="medium"
          margin="normal"
          onChange={handleInputChange}
          inputProps={{ min: 2, max: 5 }}
        />
        <Button
          fullWidth
          variant="contained"
          color="primary"
          size="medium"
          type="submit"
          margin="normal"
          onClick={handleSubmit}
        >
          Fetch
        </Button>
    </div>
    <div style={{ marginTop: 20, height: 500, width: '100%' }}>
      <DataGrid
        getRowHeight={() => 'auto'}
        rows={tableData}
        columns={columns}
        pageSize={12}
        getRowId={handleGetRowId}
      />
    </div>
    </div>
  )
}

export default GraphData;
