import React, { useState } from 'react'
import { DataGrid } from '@mui/x-data-grid'
import TextField from '@mui/material/TextField';
import { Button } from "@mui/material";
import SubnetService from "../services/SubnetService";
import { Typography } from "../components/shared/Typography";
import Box from '@mui/joy/Box';
import Radio from '@mui/joy/Radio';

const columns = [
  { field: 'month', headerName: 'Month', width: 50 },
  { field: 'principal', headerName: 'Principal', width: 125},
  { field: 'interest', headerName: 'Interest', width: 100},
  { field: 'total', headerName: 'Total', width: 100},
  { field: 'balance', headerName: 'Balance', width: 150},
  { field: 'emi', headerName: 'EMI', width: 150}
]

const Emi = () => {

  const [fld, setFld] = useState({
    p: 100000,
    r: 10,
    n: 12,
    format: 'json'
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    console.log(name, value);
    setFld({...fld, [name]: value});
    // console.log(fld)
  };  

  const handleGetRowId = (e) => {
        return e.month
  }

  const runEmi = () => {
    SubnetService.getEmi(fld.p, fld.r, fld.n, fld.format)
      .then(response => {
         // console.log(response.data);
         if ( fld.format === 'json' ) {
           setTableData(response.data);
         }
      })
      .catch(e => {
        console.log(e);
      });
  };

  const [tableData, setTableData] = useState([])

  const handleSubmit = (event) => {
    event.preventDefault();
    if ( fld.format === 'json' ) {
      runEmi();
    } else {
      const url = `https://gakfuncs.azurewebsites.net/api/emi?p=${fld.p}&r=${fld.r}&n=${fld.n}&t=${fld.format}`
      window.open(url, "_blank", "noreferrer");
    }
  };

  return (
    <div>
    <div style={{ width: '100%' }}>
        <Typography variant="h6" fontWeight={400}>
          Ammortisation Chart for prinicipal, interest and Tenor.
        </Typography>
        <Box sx={{ display: 'flex', gap: 2 }} style={{ marginBottom: "10px", marginTop: "10px"}}>
          <Radio
            checked={fld.format === 'json'}
            onChange={handleInputChange}
            value="json"
            label="json"
            name="format"
          />
          <Radio
            checked={fld.format === 'csv'}
            onChange={handleInputChange}
            value="csv"
            label="csv"
            name="format"
          />
          <Radio
            checked={fld.format === 'xlsx'}
            onChange={handleInputChange}
            value="xlsx"
            label="xlsx"
            name="format"
          />
        </Box>
        <TextField label="Principal"
          fullWidth
          id="principal"
          name="p"
          value={fld.p}
          type="number"
          size="medium"
          margin="normal"
          onChange={handleInputChange}
          inputProps={{ min: 20000, max: 500000, step: 10000 }}
        />
        <TextField label="Interest"
          fullWidth
          id="interest"
          name="r"
          value={fld.r}
          type="number"
          size="medium"
          margin="normal"
          onChange={handleInputChange}
          inputProps={{ min: 4, max: 15 }}
        />
        <TextField label="Tenure"
          fullWidth
          id="tenure"
          name="n"
          onChange={handleInputChange}
          value={fld.n}
          type="number"
          size="medium"
          margin="normal"
          inputProps={{ min: 6, max: 300, step:6 }}
        />
        <Button
          fullWidth
          variant="contained"
          color="primary"
          size="medium"
          type="submit"
          margin="normal"
          onClick={handleSubmit}
        >
          Calculate
        </Button>
    </div>
    <div style={{ marginTop: 20, height: 500, width: '100%' }}>
      <DataGrid
        rows={tableData}
        columns={columns}
        pageSize={12}
        getRowId={handleGetRowId}
      />
    </div>
    </div>
  )
}

export default Emi
