import React from "react";
import { useState, useEffect } from "react";
import { DataGrid } from '@mui/x-data-grid'
import { Card, CardContent, Typography } from "@mui/material";
import { Box } from "@mui/system";
import TextField from '@mui/material/TextField';
import { Button } from "@mui/material";
import { IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import SubnetService from "../services/SubnetService";
import Autocomplete from '@mui/material/Autocomplete';
import GraphService from "../services/GraphService";

const Clock = () => {
  const [date, setDate] = useState({
    current: new Date().toLocaleTimeString(),
    epoch: Math.floor(new Date().getTime()/1000.0)
  });

  const tick = () => {
    setDate({
      current: new Date().toLocaleTimeString(),
      epoch: Math.floor(new Date().getTime()/1000.0)
    });
  }

  useEffect(() => {
    const timerID = setInterval(() => tick(), 1000)
    return () => {
      clearInterval(timerID)
    }
  }, [])

  return (
     <div>
     <Typography gutterBottom variant="h5" component="div">
            Current Time: {date.current}.
     </Typography>
     <Typography gutterBottom variant="h5" component="div">
            Epoch: {date.epoch}.
     </Typography>
     </div>
  );
}


const Epoch = () => {

const columns = [
  { field: 'id', headerName: '#', width: 50 },
  { field: 'time', headerName: 'Time', width: 600},
]
  const [tableData, setTableData] = useState([])
  const [tzs, setTzs] = useState({tzs:[], ctzs:[]});
  const [tz, setTz] = useState("Asia/Kolkata");
  const [ctz, setCtz] = useState("");
  const [ep, setEp] = useState(Math.floor(new Date().getTime()/1000.0));

  const [epoch, setEpoch] = useState({
    epoch: "",
    utc: "",
    current: ""
  });

  useEffect(() => {
    // console.log(epoch);
    runEpoch(ep, tz, ctz);
  }, [])

  const fetchData = async () => {
      const respTzs = await GraphService.getTzs();
      const respCtzs = await GraphService.getCtzs();
      setTzs({tzs: respTzs.data, ctzs: respCtzs.data});
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleClearClick = (event) => {
    setEp("");
  };

  const handleInputChange = (event) => {
    setEp(event.target.value);
    // console.log(event.target.value);
  };  

  const handleTzChange = (event,values) => {
    // console.log(values);
    if ( values ) {
      setTz(values);
    } else {
      setTz("");
    }
  };  

  const handleCtzChange = (event,values) => {
    console.log(values);
    if ( values ) {
      setCtz(values.value);
    } else {
      setCtz("");
    }
  };  

  const handleSubmit = (event) => {
    event.preventDefault();
    runEpoch(ep, tz, ctz);
  };

  const runEpoch = (ep, tz, ctz) => {
    // console.log(ep);
    SubnetService.getEpoch(ep, tz, ctz)
      .then(response => {
         console.log(response.data);
         setEpoch(response.data);
         setEp(response.data.epoch);
         if ( response.data.country ) {
           let tdata  = response.data.country.map((t, index) => ({ time: t, id: index + 1 }));
           setTableData(tdata);
         } else {
           setTableData([]);
         }
      })
      .catch(e => {
        console.log(e);
      });
  };

  return (
    <Box>
      <Card sx={{ maxWidth: 700, margin: 1 }}>
        <CardContent
          sx={{
            "@media screen and (max-width: 600px)": {
              paddingBottom: "20px",
            },
          }}
        >
          <Typography gutterBottom variant="h5" component="div">
            Epoch Converter{" "}
          </Typography>
          <Typography variant="subtitle2" color="text.secondary">
            Epoch Converter updates the current epoch on the screen and converts
            the epoch to 'Asia/Kolkata' timezone and UTC/GMT on clicking the
            'Human Date' button. The API has been provided by Azure functions
            API call and does support other time zones and country inputs.
          </Typography>
          <Clock />
          <div style={{ width: '100%' }}>
              <TextField
                fullWidth
                label="Epoch"
                id="epoch"
                type="number"
                name="epoch"
                value={ep}
                size="small"
                onChange={handleInputChange}
                margin="normal"
                InputProps={{
                  endAdornment: (
                    <IconButton
                       sx={{ visibility: ep ? "visible" : "hidden" }}
                       onClick={handleClearClick}
                    >
                      <ClearIcon />
                    </IconButton>
                  ),
                }}
              />

              <Autocomplete
                id="tz"
                name="tz"
                onChange={handleTzChange}
                size="small"
                margin="normal"
                options={tzs.tzs.map((option) => option.value)}
                renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search TimeZone"
                  InputProps={{
                    ...params.InputProps,
                    type: 'search',
                  }}
                />
                )}
              />

              <Autocomplete style={{marginTop: "10px"}}
                id="ctz"
                name="ctz"
                onChange={handleCtzChange}
                size="small"
                margin="normal"
                options={tzs.ctzs.map((option) => ({label: option.label, value: option.value}))}
                renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search Country"
                  InputProps={{
                    ...params.InputProps,
                    type: 'search',
                  }}
                />
                )}
              />

              <Button style={{marginTop: "10px"}}
                fullWidth
                variant="contained"
                color="primary"
                size="medium"
                type="submit"
                margin="normal"
                onClick={handleSubmit}
              >
                Human Date
              </Button>
          </div>
          <div style={{marginTop: "10px"}}>
          <Typography gutterBottom variant="body2" component="div">
            Current Epoch: {epoch.epoch}.
          </Typography>
          <Typography gutterBottom variant="body2" component="div">
            Current Time: {epoch.current}.
          </Typography>
          <Typography gutterBottom variant="body2" component="div">
            UTC/GMT: {epoch.utc}.
          </Typography>
          </div>

    <div style={{ marginTop: "10px", height: 300, width: '100%' }}>
      <DataGrid
        rows={tableData}
        columns={columns}
        pageSize={12}
      />
    </div>


        </CardContent>
      </Card>
    </Box>
  );
};

export default Epoch;
