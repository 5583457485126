import "./Profile.css";

const Profile = ({user}) => {

 const logoutFromGitHub = async () => {
    localStorage.removeItem("token");
    // window.location.reload();
    window.location = window.location.pathname;
 }

 return (
   <div className="user-info">
     <h2>User Information</h2>
     <div className="avatar-container">
       <img
         src={user.avatar_url}
         alt="User Avatar"
         className="avatar"
       />
     </div>
     <div className="info-container">
       <p>Login: {user.login}</p>
       <p>ID: {user.id}</p>
       <p>Type: {user.type}</p>
       <p>Followers: {user.followers}</p>
       <p>Following: {user.following}</p>
       <p>Public Repos: {user.public_repos}</p>
     </div>
     <div className="login-container">
       <h1>Logged In via Github using Single-Sign-On(SSO) OAuth2 </h1>
       <button className="github-button" onClick={logoutFromGitHub}>
         <img
           src="https://github.githubassets.com/images/modules/logos_page/GitHub-Mark.png"
           alt="GitHub Logo"
         />
         Logout
       </button>
     </div>
   </div>
 );
};

export default Profile;
