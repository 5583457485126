import AppHome from "./AppHome";

const Invoices = () => {
  return (
    <div style={{ width: '100%' }}>
      <AppHome />
    </div>
  );
};
export default Invoices;
