import http from "../http-common";

const getDoc = (name) => {
  return http.get(`/show?q=${name}`);
};

const runPattern = (name, row) => {
  return http.get(`/run?q=${name}&v=${row}`);
};

const PatternService = { 
  getDoc,
  runPattern,
};


export default PatternService;
