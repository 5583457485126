import http from "../http-common";

const getUptoLinks = (name, n, t) => {
     return http.get(`/api/upto-n-skips-away?name=${name}&n=${n}`);
};

const getShortestPath = (p1, p2, t) => {
  if ( t === 'A2A' ) {
    return http.get(`/api/shortest-paths?p1=${p1}&p2=${p2}`);
  } else if ( t === 'M2M') {
    return http.get(`/api/shortest-paths?m1=${p1}&m2=${p2}`);
  } else {
    return http.get(`/api/shortest-paths?p1=${p1}&m1=${p2}`);
  }
};

const getActors = () => {
     return http.get(`/api/actors`);
};

const getMovies = () => {
     return http.get(`/api/movies`);
};

const getSsoUrl = (p) => {
  if ( p === "" ) {
    return http.get(`/sso/url`);
  } else {
    return http.get(`/sso/url?q=${p}`);
  }
}

const getSsoToken = (code, state) => {
  return http.get(`/sso/redirect?code=${code}&state=${state}`);
}

const getTzs = () => {
     return http.get(`/api/tzs`);
};

const getCtzs = () => {
     return http.get(`/api/ctzs`);
};

const GraphService = { 
  getUptoLinks,
  getShortestPath,
  getActors,
  getMovies,
  getSsoUrl,
  getSsoToken,
  getTzs,
  getCtzs
};

export default GraphService;
