// import axios from "axios";
import { useState, useEffect } from "react";
import { Typography } from "../components/shared/Typography";
import { DataGrid } from '@mui/x-data-grid'
import TextField from '@mui/material/TextField';
import { Button } from "@mui/material";
import GraphService from "../services/GraphService";
import MenuItem from '@mui/material/MenuItem';

const columns = [
  { field: 'link', headerName: '#', width: 50 },
  { field: 'path', headerName: 'Links', width: 600},
]

const GraphDataShortest = () => {
 
  const [fld, setFld] = useState({
    actor1: "",
    actor2: "",
    actors1: [],
    actors2: [],
    actors: [],
    movies: [],
    rtype: "A2A"
  });

  const fetchData = async (rt) => {
//      const respActors = await axios(
//        `http://localhost:8000/api/actors`
//      );
//      const respMovies = await axios(
//        `http://localhost:8000/api/movies`
//      );
      const respActors = await GraphService.getActors();
      const respMovies = await GraphService.getMovies();
      const d1 = respActors.data;
      const d2 = respMovies.data;
    
      if ( rt === 'A2A' ) {
        setFld({ rtype: rt, actor1: d1[0][0], actor2: d1[1][0], actors1: d1, actors2: d1, actors: d1, movies: d2});
      } else if ( rt === 'A2M' || rt === 'M2A' ) {
        setFld({ rtype: rt, actor1: d1[0][0], actor2: d2[0][0], actors1: d1, actors2: d2, actors: d1, movies: d2});
      } else {
        setFld({ rtype: rt, actor1: d2[0][0], actor2: d2[1][0], actors1: d2, actors2: d2, actors: d1, movies: d2});
      }
  };

  useEffect(() => {
    fetchData(fld.rtype);
  }, []);


  const rtypes = [
    {value: 'A2A', label: 'Actor-to-Actor'},
    {value: 'M2M', label: 'Movie-to-Movie'},
    {value: 'A2M', label: 'Actor-to-Movie'},
    {value: 'M2A', label: 'Movie-to-Actor'},
  ];

  const handleSelectChange = (event) => {
    // event.preventDefault(); 
    console.log(event.target.value);
    fetchData(event.target.value)
  };  

  const handleActor1Change = (event) => {
    console.log(event.target.value);
    setFld({...fld, [event.target.name]: event.target.value});
    console.log(fld)
  };  

  const handleActor2Change = (event) => {
    console.log(event.target.value);
    setFld({...fld, [event.target.name]: event.target.value});
    console.log(fld)
  };  

  const handleGetRowId = (e) => {
    return e.link
  }

  const runUptoLinks = () => {
    GraphService.getShortestPath(fld.actor1, fld.actor2, fld.rtype)
      .then(response => {
         //console.log(response.data);
         setTableData(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  };

  const [tableData, setTableData] = useState([])

  const handleSubmit = (event) => {
    event.preventDefault();
    runUptoLinks();
  };

  const title = "Actor/Movie";
  const depth = "Actor/Movie";

  return (
    <div>
        <Typography style={{ marginBottom: "20px" }} variant="h6" fontWeight={400}>
          Find Graph Links for actors-movies combinations.
        </Typography>
    <div style={{ width: '100%' }}>
        <TextField fullWidth
          id="rtype"
          name="rtype"
          select
          label="Relationship"
          value={fld.rtype}
          onChange={handleSelectChange}
          helperText="Select the Relationship Type"
        >
          {rtypes.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField fullWidth
          id="actor1"
          name="actor1"
          select
          label={title}
          size="medium"
          value={fld.actor1}
          margin="normal"
          onChange={handleActor1Change}
        >
          {fld.actors1.map((option) => (
            <MenuItem key={option[0]} value={option[0]}>
              {option[0]}
            </MenuItem>
          ))}
        </TextField>
        <TextField fullWidth
          id="actor2"
          name="actor2"
          select
          label={depth}
          size="medium"
          value={fld.actor2}
          margin="normal"
          onChange={handleActor2Change}
        >
           { fld.actors2.map((option) => (
            <MenuItem key={option[0]} value={option[0]}>
              {option[0]}
            </MenuItem>
            ))}
        </TextField>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          size="medium"
          type="submit"
          margin="normal"
          onClick={handleSubmit}
        >
          Fetch
        </Button>
    </div>
    <div style={{ marginTop: 20, height: 500, width: '100%' }}>
      <DataGrid 
        getRowHeight={() => 'auto'}
        rows={tableData}
        columns={columns}
        pageSize={12}
        getRowId={handleGetRowId}
      />
    </div>
    </div>
  )
}

export default GraphDataShortest;
