import http from "../http-subnet";

const getSubnet = (ip) => {
  return http.get(`/api/subnet?q=${ip}`);
};

const getEpoch = (e, tz, ctz) => {
  if ( e === "" ) {
    if ( tz === "" ) {
      if ( ctz === "" ) {
        return http.get(`/api/epoch`);
      } else {
        return http.get(`/api/epoch?country=${ctz}`);
      }
    } else {
      if ( ctz === "" ) {
        return http.get(`/api/epoch?intz=${tz}`);
      } else {
        return http.get(`/api/epoch?intz=${tz}&country=${ctz}`);
      }
    }
  } else {
    if ( tz === "" ) {
      if ( ctz === "" ) {
        return http.get(`/api/epoch?e=${e}`);
      } else {
        return http.get(`/api/epoch?e=${e}&country=${ctz}`);
      }
    } else {
      if ( ctz === "" ) {
        return http.get(`/api/epoch?e=${e}&intz=${tz}`);
      } else {
        return http.get(`/api/epoch?e=${e}&intz=${tz}&country=${ctz}`);
      }
    }
  }
};

const getEmi = (p,r,n,t) => {
  return http.get(`/api/emi?p=${p}&r=${r}&n=${n}&t=${t}`);
};

const SubnetService = { 
  getSubnet,
  getEpoch,
  getEmi,
};


export default SubnetService;
