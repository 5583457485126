import { useState, useEffect } from "react";
import PatternService from "../services/PatternService";

const PatternRun = ({p}) => {
  const [output, setOutput] = useState();
  const [row, setRow] = useState(''+p.def);

  useEffect(() => {
    retrieveDoc();
  }, [p.name]);

  const onChangeRow = (e) => {
     setRow(e.target.value);
  };

  const runPattern = (e) => {
    e.preventDefault();
    PatternService.runPattern(p.name, row)
      .then(response => {
         // console.log(response.data);
         setOutput(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  };

  const retrieveDoc = () => {
    PatternService.runPattern(p.name, p.def)
      .then((response) => {
         setRow(p.def);
         setOutput(response.data);
         // console.log(response.data);
       })  
      .catch((e) => {
         console.log(e);
       }
      ); 
  };

  return (
          <div>
          <form>
            <label>
              {p.lbl}
              <input 
                id="row"
                name="row"
                value={row}
                onChange={onChangeRow}
              />
            </label>
            <input 
               type="submit" 
               value="Run Pattern"
               className="btn btn-success"
               onClick={runPattern}
            />
          </form>
          <br/>
          <pre style={{ fontFamily: "monospace" }}>{output}</pre>
          </div>
  );
};

export default PatternRun;
