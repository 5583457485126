import React from "react";
import { useState } from "react";
import SubnetService from "../services/SubnetService";

import {
  Typography,
  Grid,
  Paper,
  TextField,
  Button,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { blue } from "@mui/material/colors";
 
 
const theme = createTheme({
  palette: {
    primary: {
      main: blue[500],
    },
  },
});
 
 
function Subnet() {
  const [formData, setFormData] = useState({
    input: "",
    inputIP: "",
    inputLong: "",
    inputHex: "",
    cidr: "",
    iprange: "",
    iprangeint: "",
    iprangehex: "",
    ipaddresses: "",
    maskbits: "",
    netmask: "",
    netmaskhex: ""
  });
 
  const runSubnet = () => {
    SubnetService.getSubnet(formData.input)
      .then(response => {
         console.log(response.data);
         response.data.ipAddresses = '' + response.data.ipAddresses;
         setFormData(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  };
 
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    console.log(name, value);
    setFormData({...formData, input: value});
    console.log(formData)
  };
 
  const handleSubmit = (event) => {
    event.preventDefault();
 
    // Validate form data
    const errors = {};
 
    if (!formData.input) {
      errors.input = "Please enter a valid ip/cidr";
    }
 
    // Submit form data if there are no errors
    if (Object.keys(errors).length === 0) {
      runSubnet();
    }
  };
 
 
  return (
    <ThemeProvider theme={theme}>
      <Typography variant="h2" mb={3}></Typography>
      <Grid
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          minHeight: "100vh",
          width: "fit-content",
          margin: "auto",
        }}
      >
        <Grid container spacing={4} sx={{width:"fit-content",margin:"auto",display:"flex",justifyContent:"center",}}>
          <Grid item xs={12} md={8}>
            <Paper sx={{ p: 4 }} elevation={3}>
              <Typography variant="h5" mb={3}>
                Subnet Calculation
              </Typography>
          <Typography variant="body2" color="text.primary">
            Subnet Calculator calculates the IP Address range for a given IP 
            Address and CIDR(Classless Inter-Domain Routing). Networks are 
            divided into subnets for management and administration.
          </Typography>
          <Typography variant="body2" color="text.primary">
            Examples: 192.168.1.4/29, 10.1.22.34/24
          </Typography>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>

                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="IP Address / CIDR"
                      name="input"
                      variant="outlined"
                      margin="normal"
                      onChange={handleInputChange}
                      required
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      type="submit"
                    >
                      Submit
                    </Button>
                  </Grid>


                  <Grid item xs={4} md={3}>
                    <TextField
                      fullWidth
                      label="Input"
                      name="input"
                      variant="outlined"
                      value={formData.input}
                      inputProps={{ readOnly: true }}
                    />
                  </Grid>
                  <Grid item xs={4} md={3}>
                    <TextField
                      fullWidth
                      label="Input IP"
                      name="inputIP"
                      variant="outlined"
                      value={formData.inputIP}
                      inputProps={{ readOnly: true }}
                    />
                  </Grid>
                  <Grid item xs={4} md={3}>
                    <TextField
                      fullWidth
                      label="Input Long"
                      name="inputLong"
                      variant="outlined"
                      value={formData.inputLong}
                      inputProps={{ readOnly: true }}
                    />
                  </Grid>
                  <Grid item xs={4} md={3}>
                    <TextField
                      fullWidth
                      label="Input Hex"
                      name="inputHex"
                      variant="outlined"
                      value={formData.inputHex}
                      inputProps={{ readOnly: true }}
                    />
                  </Grid>



                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="CIDR"
                      name="cidr"
                      variant="outlined"
                      value={formData.cidr}
                      inputProps={{ readOnly: true }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="CIDR IP Range"
                      name="iprange"
                      variant="outlined"
                      value={formData.iprange}
                      inputProps={{ readOnly: true }}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="IP Range Long"
                      name="iprangeint"
                      variant="outlined"
                      value={formData.iprangeint}
                      inputProps={{ readOnly: true }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="IP Range Hex"
                      name="iprangehex"
                      variant="outlined"
                      value={formData.iprangehex}
                      inputProps={{ readOnly: true }}
                    />
                  </Grid>


                  <Grid item xs={4} md={2}>
                    <TextField
                      fullWidth
                      label="IPs in Range"
                      name="ipaddresses"
                      variant="outlined"
                      value={formData.ipaddresses}
                      inputProps={{ readOnly: true }}
                    />
                  </Grid>
                  <Grid item xs={4} md={2}>
                    <TextField
                      fullWidth
                      label="Mask Bits"
                      name="maskbits"
                      variant="outlined"
                      value={formData.maskbits}
                      inputProps={{ readOnly: true }}
                    />
                  </Grid>
                  <Grid item xs={4} md={3}>
                    <TextField
                      fullWidth
                      label="Subnet Mask"
                      name="netmask"
                      variant="outlined"
                      value={formData.netmask}
                      inputProps={{ readOnly: true }}
                    />
                  </Grid>
                  <Grid item xs={4} md={5}>
                    <TextField
                      fullWidth
                      label="Hex Subnet Mask"
                      name="netmaskhex"
                      variant="outlined"
                      value={formData.netmaskhex}
                      inputProps={{ readOnly: true }}
                    />
                  </Grid>

                </Grid>
              </form>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};
 
 
export default Subnet;
